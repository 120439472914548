export default [
    {
        path: "/home",
        name: "home",
        component: () => import("../views/homepage"),
    },
    {
        path: "/checkout",
        name: "checkout",
        component: () => import("../views/checkout"),
    },

    {
        path: "/:catchAll(.*)",
        redirect: "/home"
    }
];